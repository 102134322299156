import React, { useState } from "react"
import { TaskType } from "../../pages/home"
import axios from "axios"
import { BASE_URL, getCookie, setCookie } from "../../utils"
import jwtDecode from "jwt-decode"
import { FaUsers } from 'react-icons/fa'
import { BiSolidUserCheck } from 'react-icons/bi'
import { useNavigate } from "react-router-dom"
import { Social, UserType } from "../../pages/people"
import Avatar from "boring-avatars"
import { AiFillTwitterCircle, AiFillYoutube, AiFillInstagram, AiFillFacebook } from 'react-icons/ai'
import { BsTiktok, BsYoutube, BsSnapchat, BsTelegram } from 'react-icons/bs'

interface UserProps {
  name: string,
  bio: string,
  completed_on: string,
  quantity: number,
  social: Social[]
}

const socials = {
  instagram: <AiFillInstagram color="#1f696d" size={18} />,
  twitter: <AiFillTwitterCircle color="#1f696d" size={18}  />,
  facebook: <AiFillFacebook color="#1f696d" size={18}  />,
  snapchat: <BsSnapchat color="#1f696d" size={18}  />,
  tiktok: <BsTiktok color="#1f696d" size={18} />,
  youtube: <AiFillYoutube color="#1f696d" size={18} />,
  telegram: <BsTelegram color="#1f696d" size={18} />
}

const WhoUser: React.FC<UserProps> = (props) => {

  const getDate = (date: string) => {
    const newDate = new Date(parseInt(date.split("-")[0]), parseInt(date.split("-")[1]), parseInt(date.split("-")[2]))
    const days = new Date().getDate() - newDate.getDate()
    if(days == 0)
      return "Today"
    if(days > 0 && days < 7){
      if (days == 1)
        return days + " day ago"
      return days + " days ago"
    }
    return date.replaceAll("-", "/")
  }

  return (
    <div className="p-4">
      <div style={{ maxWidth: 484 }} className="items-center rounded-md flex shadow-lg p-8 bg-[white]">
        <div>
          <div className="pr-8">
            <Avatar
              size={64}
              name={props.name}
              variant="beam"
              colors={["#1E6B72", "#1f696d", "#20676A", "#1E6A6F", "#6d1f23"]}
            />
          </div>
        </div>
        <div>
          <h1 className="text-xl font-semibold">{props.name}</h1>
          <p className="font-Gabarito">Completed this task {props.quantity} times</p>
          <p className="text-[gray] font-Gabarito">Last time {getDate(props.completed_on)}</p>
          <div className="gap-2 mt-2 items-center flex">
            {
              props.social.map((e: Social) => (
                e.url ? (
                  <a title={e.url.split("/")[e.url.split("/").length - 1]} href={e.url}>
                    {socials[e.name]}
                  </a>
                ):null
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhoUser