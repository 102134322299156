import { useEffect, useState } from "react"
import { BASE_URL, getCookie, getUser, isLoggedIn, setCookie } from "../../utils"
import axios from "axios"
import Task from "../../components/task"
import Header from "../../components/header"
import Loading from "../../components/loading"
import Title from "../../components/title"

export interface TaskType {
  created_on: string,
  user_quantity?: number,
  name: string,
  quantity: number,
  reported: number,
  task_id: string
}


const Home = () => {

  const [tasks, setTasks] = useState<TaskType[]>([])
  const [title, setTitle] = useState("")
  const [completedTasks, setCompletedTasks] = useState<TaskType[]>([])
  const [editable, setEditable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getTasks = async () => {
    setIsLoading(true)
    const guest = !(await isLoggedIn());
    await axios.get(BASE_URL + "/tasks/", !guest ? {
      headers: {
        Authorization: "Bearer " + getCookie("jwt-token")
      }
    } : { headers: { Authorization: "" } })
      .then(res => {
        setEditable(res.data.param.editable)
        setTasks(res.data.param.res)
      })
      .catch(err => console.log(err.response.data))
    setIsLoading(false)
  }

  const setCompleted = (task: TaskType) => {
    const array = completedTasks
    array.push(task)
    setCompletedTasks(array)
  }

  const isTaskCompleted = (task: TaskType) => {
    return completedTasks.includes(task)
  }

  useEffect(() => {
    getTasks()
  }, [])

  return (
    <div className="mt-36 h-screen w-screen justify-around flex">
      <div>
        <Title subtitle="<h1>These are some tasks you can do to kill time ☕</h1>"/>
        <div style={{ minHeight: 240, maxHeight: 640 }} className="border-lg overflow-y-scroll mt-8 justify-around flex">
          {
            isLoading ? (
              <Loading />
            ) : (
              <div>
                {
                  tasks.map((task: TaskType) => (
                    <Task
                      onCompleted={() => setCompleted(task)}
                      isTaskCompleted={() => isTaskCompleted(task)}
                      editable={editable}
                      task={task}
                    />
                  ))
                }
              </div>
            )
          }
        </div>
      </div>
    </div>
  )

}

export default Home