import { useEffect, useState } from "react"
import Title from "../../components/title"
import { BASE_URL, getCookie, getUser, isLoggedIn } from "../../utils"
import { Social } from "../people"
import axios from "axios"
import { FaLess } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import Loading from "../../components/loading"

const social: Social[] = [
  {
    name: 'instagram',
    url: 'https://instagram.com/'
  },
  {
    name: 'twitter',
    url: 'https://twitter.com/@'
  },
  {
    name: 'facebook',
    url: 'https://facebook.com/people/'
  },
  {
    name: 'snapchat',
    url: 'https://snapchat.com/add/'
  },
  {
    name: 'tiktok',
    url: 'https://tiktok.com/@'
  },
  {
    name: 'youtube',
    url: 'https://youtube.com/@'
  },
  {
    name: 'telegram',
    url: 'https://t.me/'
  }
]

const Dashboard = () => {

  const [userSchema, setUserSchema] = useState({
    email: '',
    name: '',
    social: {
      instagram: '',
      twitter: '',
      facebook: '',
      snapchat: '',
      tiktok: '',
      youtube: '',
      telegram: ''
    },
    password: ''
  })
  const navigate = useNavigate()
  const [changed, setChanged] = useState(false)
  const [isLoading, setiIsLoading] = useState(false)

  useEffect(() => {
    handleAuth()
  }, [])

  const handleAuth = async () => {

    const isLoggedIn_ = await isLoggedIn()

    if (!isLoggedIn_) {
      navigate("/")
      return;
    }

    const user: any = await getUser()
    const newUserSchema: any = { ...userSchema }
    newUserSchema.social = setSocial(newUserSchema.social, user)
    newUserSchema.email = user.email
    newUserSchema.name = user.name
    setUserSchema(newUserSchema)
    console.log(userSchema)
  }

  const setSocial = (socialSchema: any, user: any) => {
    Object.keys(socialSchema).forEach((social: any) => {
      const userSocialValue = () => {
        let res = ""
        Object.values(user.social).forEach((userSocial: any) => {
          if (userSocial.name == social && userSocial.url !== null) {
            let userName = userSocial.url.split("/")[userSocial.url.split("/").length - 1]
            if (userName.charAt(0) == "@") {
              userName = userName.substring(1)
            }
            res = userName
          }
        })
        return res
      }
      socialSchema[social] = userSocialValue()
    });
    return socialSchema
  }

  const handleUserSchema = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(false)
    const newSchema: any = { ...userSchema }
    newSchema[e.target.name] = e.target.value
    setUserSchema(newSchema)
  }

  const handleUserSocialSchema = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(false)
    const newSchema: any = { ...userSchema }
    newSchema.social[e.target.name] = e.target.value
    setUserSchema(newSchema)
  }

  const changeData = async () => {
    const user: any = await getUser()
    console.log(userSchema)
    await axios.put(BASE_URL + "/users/" + user.user_id, userSchema)
    .then(res => {
      setChanged(true)
    })
    .catch(err => console.log(err))
  }

  const isOneSocialPresent = () => {
    const values = Object.values(userSchema.social)
    console.log(values)
    let res = false
    values.forEach((value) => {
      if (value !== "") {
        res = true;
      }
    })
    return res;
  }

  return (
    <div className="mt-36 h-screen w-screen justify-around flex">
      <div>
        <div className="mt-8 p-4">
          <div className="flex-block">
            <div className="pl-0 p-2">
              <h2 className="pb-1 text-lg font-Gabarito">Full Name</h2>
              <input

                placeholder="Name Surname"
                className="pl-4 rounded-md border-2 border-[] p-3"
                onChange={(e) => handleUserSchema(e)}
                type="text"
                name="name"
                value={userSchema.name}
              />
            </div>
            <div className="pl-0 p-2">
              <h2 className="pb-1 text-lg font-Gabarito">E-mail</h2>
              <input
                disabled
                placeholder="example@gmail.com"
                className="pl-4 rounded-md border-2 border-[] p-3"
                onChange={(e) => handleUserSchema(e)}
                type="text"
                name="email"
                value={userSchema.email}
              />
            </div>
          </div>
          <div className="pl-0 p-2">
            <h2 className="pb-1 text-lg font-Gabarito">Password</h2>
            <input
              placeholder="Create a new password..."
              className="pl-4 rounded-md border-2 border-[] p-3"
              onChange={(e) => handleUserSchema(e)}
              type="password"
              name="password"
              value={userSchema.password}
            />
          </div>
        </div>
        <div>
          {
            social.map((e: Social) => (
              <div className="pl-4 p-2">
                <div className="bg-[white] font-Gabarito rounded-md border-2 flex pl-4 p-2">
                  <p>{e.url.substring(8)}</p>
                  <input
                    placeholder="example"
                    className="outline-none border-[]"
                    onChange={(e) => handleUserSocialSchema(e)}
                    type="text"
                    name={e.name}
                    value={userSchema.social[e.name]}
                  />
                </div>
              </div>
            ))
          }
        </div>
        <div className="p-4">
          {
            isLoading ? (
              <Loading/>
            ):(
              changed ? (
                <button disabled className="bg-opacity-60 h-14 rounded-md font-Gabarito w-32 text-[white] bg-[#1f696d] p-2">Changed</button>
              ):(
                userSchema.name !== "" && userSchema.email !== "" && isOneSocialPresent() ? (
                  <button onClick={() => changeData()} className="h-14 rounded-md font-Gabarito w-32 text-[white] bg-[#1f696d] p-2">Change</button>
                ):(
                  <button disabled className="bg-opacity-60 h-14 rounded-md font-Gabarito w-32 text-[white] bg-[#1f696d] p-2">Change</button>
                )
              )
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Dashboard