import { useEffect, useState } from "react"
import { BASE_URL, getCookie, isLoggedIn, setCookie } from "../../utils"
import axios from "axios"
import Task from "../../components/task"
import Header from "../../components/header"
import Loading from "../../components/loading"
import User from "../../components/user"
import Title from "../../components/title"


const Create = () => {

  const [name, setName] = useState("")
  const [isLoggedIn_, setIsLoggedIn_] = useState(false)
  const [created, setCreated] = useState(false)

  const createTask = async () => {
    await axios.post(BASE_URL + "/tasks/", {
      name: name
    }, { headers: { Authorization: 'Bearer ' + getCookie('jwt-token') } })
      .then(res => {
        setCreated(true)
        setName("")
      })
      .catch(err => console.log(err.response.data))
  }

  useEffect(() => {
    handleAuth()
  }, [])

  const handleAuth = async () => {
    const loggedIn = await isLoggedIn()
    setIsLoggedIn_(loggedIn)
  }

  return (
    <div className="mt-36 h-screen w-screen justify-around flex">
      <div>
        <Title subtitle="<h1>Create a new task and make people have fun</h1>" />
        <div className="mt-8 flex-block p-4">
          <input
            autoFocus
            placeholder="Drink Starbucks without using your hands..."
            className="rounded-md border-2 border-[] p-4"
            style={{ width: 340 }}
            maxLength={154}
            onChange={(e) => setName(e.target.value)}
            type="text"
            value={name}
          />
          {
            isLoggedIn_ && name !== "" ? (
              <div className="button">
                <button onClick={() => createTask()} className="h-14 rounded-md font-Gabarito w-32 text-[white] bg-[#1f696d] p-2">Create</button>
              </div>
            ) : (
              <div className="button">
                <button disabled className="h-14 rounded-md bg-opacity-60 font-Gabarito w-32 text-[white] bg-[#1f696d] p-2">{created ? "Created!" : "Create"}</button>
              </div>
            )
          }
        </div>
        <div className="flex pl-4">
          <h1 className="text-md font-Gabarito font-normal">Fun fuct: Probably, you'll never see your task again!</h1>
        </div>
      </div>
    </div>
  )

}

export default Create