import { useEffect, useState } from "react"
import { getUser, isLoggedIn } from "../../utils"

interface TitleProps{
  subtitle: any
}

const Title: React.FC<TitleProps> = ({ subtitle }) => {

  const [title, setTitle] = useState("")

  const generateHello = async () => {
    const time = new Date().getHours()
    let title_ = ""

    if (time > 4 && time < 12) {
      title_ = "Good morning." 
    }
    if (time >= 12 && time < 18) {
      title_ = "Good afternoon."
    }
    if (time >= 18 || time > 0 && time <= 4) {
      title_ = "Good evening."
    }
    
    const guest = !(await isLoggedIn())
    if (!guest) {
      let user: any = await getUser()
      const userName = user['name'].split(" ")[0]
      title_ = title_.replace(".", " ") + userName + "."
    }

    setTitle(title_)
  }

  useEffect(() => {
    generateHello()
  },[])

  return (
    <div className="pl-4">
      <h1 className="text-2xl font-Gabarito font-normal">{title}</h1>
      <div className="mt-2">
        <h2 className="text-xl font-Gabarito font-normal"><span className="decoration-2 underline-offset-4 decoration-[#1f696d] underline" >Bored</span> and <span className="decoration-2 underline-offset-4	decoration-[#1f696d] underline" >tired of procrastinating?</span></h2>
        <h1 dangerouslySetInnerHTML={{ __html: subtitle }} className="text-xl font-Gabarito font-normal"></h1>
      </div>
    </div>
  )
}

export default Title