import { useState } from "react";
import { RotateLoader, SquareLoader } from "react-spinners";

const Loading = () => {

  const sentences = [
    "Create, do things and have fun",
    "Never stop experiencing new things! It's funny",
    "We have more than 200 tasks you can do.",
    "It's impossible to get bored with us"
  ]

  const [sentence, setSentence] = useState(sentences[Math.floor(Math.random() * 4)])

  return (
    <div className="items-center justify-around flex">
      <div>
        <div className="items-center justify-around flex">
          <RotateLoader size={14} color="#1f696d" loading={true} />
        </div>
        <div className="mt-8">
          <h2 className="text-lg font-Gabarito">{sentence}</h2>
        </div>
      </div>
    </div>
  );
}

export default Loading