import React, { useState } from "react"
import { TaskType } from "../../pages/home"
import axios from "axios"
import { BASE_URL, getCookie, setCookie } from "../../utils"
import jwtDecode from "jwt-decode"
import { FaUsers } from 'react-icons/fa'
import { BiSolidUserCheck } from 'react-icons/bi'
import { useNavigate } from "react-router-dom"

interface TaskProps {
  task: TaskType
  isTaskCompleted: () => boolean,
  onCompleted: () => void
  editable: boolean
}

const Task: React.FC<TaskProps> = (props) => {

  const [isLoafing, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const addCompleted = async () => {
    setIsLoading(true)
    const cookie: string | undefined = getCookie("jwt-token")
    let userId

    if (cookie !== undefined) {
      userId = jwtDecode<any>(cookie).sub.user_id
    } else {
      userId = 0
    }
    console.log({
      task_id: props.task.task_id,
      user_id: userId
    })
    await axios.post(BASE_URL + "/tasks/user/", {
      task_id: props.task.task_id,
      user_id: userId
    }, { headers: { Authorization: "Bearer " + cookie } })
      .then(res => {
        props.onCompleted()
        props.task.quantity = res.data.param.new.quantity
        props.task.user_quantity = res.data.param.new.user_quantity
      })
    setIsLoading(false)
  }

  return (
    <div className="p-4">
      <div style={{ maxWidth: 484 }} className="rounded-md justify-between flex-block shadow-lg p-8 bg-[white]">
        <div>
          <h1 className="text-xl font-semibold">{props.task.name}</h1>
          <div className="mt-2 items-center flex">
            <div className="pr-2"><FaUsers color="#1f696d" /></div>
            <p className="text-[gray] font-Gabarito">Completed {props.task.quantity} times
              <span className="text-[black]">
                (<span onClick={() => navigate("/people/task/" + props.task.task_id)} className="cursor-pointer text-[#1f696d] underline">By who?</span>)
              </span>
            </p>
          </div>
          {
            props.editable ? (
              <div className="items-center flex">
                <div className="pr-2"><BiSolidUserCheck color="#1f696d" /></div>
                <p className="text-[gray] font-Gabarito">Completed by you {props.task.user_quantity} times</p>
              </div>
            ) : null
          }
        </div>
        <div className="buttons items-center justify-right flex">
          <div className="block-flex">
            {
              props.editable ? (
                isLoafing ? (
                  <div className="p-1">
                    <button disabled className="rounded-md bg-opacity-60 font-Gabarito w-32 text-[white] bg-[#1f696d] p-2">Loading...</button>
                  </div>
                ) : (
                  props.isTaskCompleted() ? (
                    <div className="p-1">
                      <button disabled className="rounded-md bg-opacity-60 italic font-Gabarito w-32 text-[white] bg-[#1f696d] p-2">Completed!</button>
                    </div>
                  ) : (
                    <div className="p-1">
                      <button onClick={() => addCompleted()} className="rounded-md font-Gabarito w-32 text-[white] bg-[#1f696d] p-2">I did it</button>
                    </div>
                  )
                )
              ) : (
                <div className="p-1">
                  <button onClick={() => navigate("/login")} className="rounded-md font-Gabarito w-32 text-[white] bg-[#1f696d] p-2">I did it</button>
                </div>
              )
            }
            {
              props.editable ? (
                <div className="p-1">
                  <button className="border-2 rounded-md font-Gabarito w-32 text-[#1f696d] border-[#1f696d] p-2">Report</button>
                </div>
              ): null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Task