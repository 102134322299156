import { useEffect, useState } from "react"
import { BASE_URL, getCookie, isLoggedIn, setCookie } from "../../utils"
import axios from "axios"
import Task from "../../components/task"
import Header from "../../components/header"
import Loading from "../../components/loading"
import User from "../../components/user"
import Title from "../../components/title"
import { useNavigate } from "react-router-dom"
import { RotateLoader } from "react-spinners"
import { Social } from "../people"

const social: Social[] = [
  {
    name: 'instagram',
    url: 'https://instagram.com/'
  },
  {
    name: 'twitter',
    url: 'https://twitter.com/@'
  },
  {
    name: 'facebook',
    url: 'https://facebook.com/people/'
  },
  {
    name: 'snapchat',
    url: 'https://snapchat.com/add/'
  },
  {
    name: 'tiktok',
    url: 'https://tiktok.com/@'
  },
  {
    name: 'youtube',
    url: 'https://youtube.com/@'
  },
  {
    name: 'telegram',
    url: 'https://t.me/'
  }
]

const Signup = () => {

  const [userSchema, setUserSchema] = useState({
    email: '',
    name: '',
    social: {
      instagram: '',
      twitter: '',
      facebook: '',
      snapchat: '',
      tiktok: '',
      youtube: '',
      telegram: ''
    },
    password: ''
  })
  const [userDataSubmitted, setUserDataSubmitted] = useState(false)
  const [error, setError] = useState<null | string>(null)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)


  const signup = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    await axios.post(BASE_URL + "/users/signup", userSchema)
      .then(res => {
        setCookie('jwt-token', res.data.param.token, 14)
        navigate("/")
        navigate(0)
      })
      .catch(err => setError(err.response.data.error.message))
    setIsLoading(false)
  }


  const handleUserSchema = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSchema: any = { ...userSchema }
    newSchema[e.target.name] = e.target.value
    setUserSchema(newSchema)
  }

  const handleUserSocialSchema = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSchema: any = { ...userSchema }
    newSchema.social[e.target.name] = e.target.value
    setUserSchema(newSchema)
  }

  const isOneSocialPresent = () => {
    const values = Object.values(userSchema.social)
    console.log(values)
    let res = false
    values.forEach((value) => {
      if (value !== "") {
        res = true;
      }
    })
    return res;
  }


  return (
    <div className="mt-36 h-screen w-screen justify-around flex">
      <div className="">
        {
          userDataSubmitted ? (
            <div>
              <h1 className="font-semibold text-2xl font-Gabarito">SIGN UP</h1>
              <h2 className="text-[gray] text-lg font-Gabarito">Write at least one of your social <br /> media accounts</h2>
            </div>
          ) : (
            <div>
              <h1 className="font-semibold text-2xl font-Gabarito">SIGN UP</h1>
              <h2 className="text-[gray] text-lg font-Gabarito">Have an account? <br /> <span onClick={() => navigate("/login")} className="decoration-[#1f696d] underline">log in</span>  now</h2>
            </div>
          )
        }
        <div className="mt-4">
          {
            userDataSubmitted ? (
              <form onSubmit={(e) => signup(e)} action="">
                <div>
                  {
                    social.map((e: Social) => (
                      <div className="pl-0 p-2">
                        <div className="bg-[white] font-Gabarito rounded-md border-2 flex pl-4 p-2">
                          <p>{e.url.substring(8)}</p>
                          <input
                            autoFocus
                            placeholder="example"
                            className="outline-none border-[]"
                            onChange={(e) => handleUserSocialSchema(e)}
                            type="text"
                            name={e.name}
                            value={userSchema.social[e.name]}
                          />
                        </div>
                      </div>
                    ))
                  }
                </div>
                {
                  isOneSocialPresent() ? (
                    <div className="pl-0 p-4">
                      <button onClick={() => setUserDataSubmitted(true)} className="h-14 rounded-md font-Gabarito w-32 text-[white] bg-[#1f696d] p-2">SIGN UP</button>
                    </div>
                  ) : (
                    <div className="pl-0 p-4">
                      <button disabled className="h-14 rounded-md bg-opacity-60 font-Gabarito w-32 text-[white] bg-[#1f696d] p-2">SIGN UP</button>
                    </div>
                  )
                }
              </form>
            ) : (
              <>
                <div className="flex">
                  <div className="pl-0 p-2">
                    <h2 className="pb-1 text-lg font-Gabarito">Full Name</h2>
                    <input
                      autoFocus
                      placeholder="Name Surname"
                      className="pl-4 rounded-md border-2 border-[] p-3"
                      onChange={(e) => handleUserSchema(e)}
                      type="text"
                      name="name"
                      value={userSchema.name}
                    />
                  </div>
                  <div className="pl-0 p-2">
                    <h2 className="pb-1 text-lg font-Gabarito">E-mail</h2>
                    <input
                      autoFocus
                      placeholder="example@gmail.com"
                      className="pl-4 rounded-md border-2 border-[] p-3"
                      onChange={(e) => handleUserSchema(e)}
                      type="text"
                      name="email"
                      value={userSchema.email}
                    />
                  </div>
                </div>
                <div className="pl-0 p-2">
                  <h2 className="pb-1 text-lg font-Gabarito">Password</h2>
                  <input
                    autoFocus
                    placeholder="Your secret password..."
                    className="pl-4 rounded-md border-2 border-[] p-3"
                    onChange={(e) => handleUserSchema(e)}
                    type="password"
                    name="password"
                    value={userSchema.password}
                  />
                </div>
                {
                  isLoading ? (
                    <div className="pl-0 p-4">
                      <button className="h-14 rounded-md font-Gabarito w-32 text-[white] bg-[#1f696d] p-2">
                        <RotateLoader size={4} color="white" loading={true} />
                      </button>
                    </div>
                  ) : (
                    userSchema.name !== "" && userSchema.email !== "" && userSchema.password !== "" ? (
                      <div className="pl-0 p-4">
                        <button onClick={() => setUserDataSubmitted(true)} className="h-14 rounded-md font-Gabarito w-32 text-[white] bg-[#1f696d] p-2">NEXT</button>
                      </div>
                    ) : (
                      <div className="pl-0 p-4">
                        <button disabled className="h-14 rounded-md bg-opacity-60 font-Gabarito w-32 text-[white] bg-[#1f696d] p-2">NEXT</button>
                      </div>
                    )
                  )
                }
              </>
            )
          }
        </div>
      </div>
    </div>
  )

}

export default Signup