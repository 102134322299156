import Avatar from "boring-avatars"
import { FiLogIn } from 'react-icons/fi'
import React, { useEffect, useState } from "react"
import { eraseCookie, getUser, isLoggedIn } from "../../utils"
import { useNavigate } from "react-router-dom"
import Menu from "../menu"
import { CSSTransition } from 'react-transition-group'
import { BiLogOut } from "react-icons/bi"

interface HeaderProps {
  page: null | "home" | "people" | "create"
}

const Header: React.FC<HeaderProps> = (props) => {

  const navigate = useNavigate()
  const [visibleMenus, setVisibleMenus] = useState({
    profile: false,
    menu: false
  })
  const [user, setUser] = useState<any>({})
  const [isLoggedIn_, setIsLoggedIn_] = useState(false)

  useEffect(() => {
    handleAuth()
  }, [])

  const handleAuth = async () => {
    const loggedIn = await isLoggedIn()
    if (loggedIn) {
      const usr: any = await getUser()
      setIsLoggedIn_(loggedIn)
      setUser(usr)
    }
  }

  return (
    <div className="top-0 fixed items-center h-32 justify-around flex w-screen">
      <div className="flex-none bg-[white] rounded-lg h-20 items-center p-4 shadow-md">
        <div className="cursor-pointer pr-8">
          <img width="38" src={require("../../assets/image.png")} alt="" />
        </div>
        <ul className="flex gap-8">
          {
            props.page === "home" ? (
              <li className="cursor-pointer text-lg decoration-2 underline-offset-4	decoration-[#1f696d] underline font-normal font-Gabarito">Do Something</li>
            ) : (
              <li onClick={() => navigate("/")} className="cursor-pointer text-lg font-normal font-Gabarito">Do Something</li>
            )
          }
          {
            props.page === "people" ? (
              <li className="cursor-pointer text-lg decoration-2 underline-offset-4	decoration-[#1f696d] underline font-normal font-Gabarito">Unbored people</li>
            ) : (
              <li onClick={() => navigate("/people")} className="cursor-pointer text-lg font-normal font-Gabarito">Unbored people</li>
            )
          }
          {
            props.page === "create" ? (
              <li className="cursor-pointer text-lg decoration-2 underline-offset-4	decoration-[#1f696d] underline font-normal font-Gabarito">Add tasks</li>
            ) : (
              <li onClick={() => navigate("/create")} className="cursor-pointer text-lg font-normal font-Gabarito">Add tasks</li>
            )
          }
        </ul>
        {
          isLoggedIn_ ? (
            <div>
              <div onClick={() => setVisibleMenus({ ...visibleMenus, profile: !visibleMenus.profile})} className="cursor-pointer pl-8">
                <Avatar
                  size={45}
                  name={user?.name}
                  variant="beam"
                  colors={["#1E6B72", "#1f696d", "#20676A", "#1E6A6F", "#6d1f23"]}
                />
              </div>
              <CSSTransition
                in={visibleMenus.profile}
                timeout={300}
                classNames="fade"
                unmountOnExit
              >
                <Menu
                  visible={visibleMenus.profile}
                  backgroundColor={"white"}
                  color={"black"}
                >
                  <div
                    onClick={() => navigate("/dashboard")}
                    className="hover:opacity-80 cursor-pointer mb-1 mt-1 p-1 pr-4 pl-4">
                    <h2 className="font-Gabarito">Dashboard</h2>
                  </div>

                  <div onClick={() => {
                    eraseCookie("jwt-token")
                    navigate(0)
                  }} className="hover:opacity-80 cursor-pointer items-center flex mb-1 mt-1 p-1 pr-4 pl-4">
                    <div className="pr-2"><BiLogOut /></div>
                    <h2 className="font-Gabarito">Log out</h2>
                  </div>
                </Menu>
              </CSSTransition>
            </div>
          ) : (
            <div onClick={() => navigate("/login")} className="items-center flex pl-4">
              <FiLogIn size={18} />
            </div>
          )
        }
      </div>
      <div className="w-4/5 p-4 justify-between none-flex">
        <div onClick={() => setVisibleMenus({ ...visibleMenus, menu: !visibleMenus.menu})}>
          <div className="shadow rounded-lg grid gap-y-2 bg-[white] p-3">
            <div className="rounded-lg h-1 w-10 bg-[black]"></div>
            <div className="rounded-lg h-1 w-10 bg-[black]"></div>
            <div className="rounded-lg h-1 w-10 bg-[black]"></div>
          </div>
          <CSSTransition
                in={visibleMenus.menu}
                timeout={300}
                classNames="fade"
                unmountOnExit
              >
                <Menu
                  visible={visibleMenus.menu}
                  backgroundColor={"white"}
                  color={"black"}
                >
                  <div
                    onClick={() => navigate("/")}
                    className="hover:opacity-80 cursor-pointer mb-1 mt-1 p-1 pr-4 pl-4">
                    <h2 className="font-Gabarito">Do Something</h2>
                  </div>

                  <div onClick={() => navigate("/people")} className="hover:opacity-80 cursor-pointer mb-1 mt-1 p-1 pr-4 pl-4">
                    <h2 className="font-Gabarito">Unbored People</h2>
                  </div>

                  <div
                    onClick={() => navigate("/create")}
                    className="hover:opacity-80 cursor-pointer mb-1 mt-1 p-1 pr-4 pl-4">
                    <h2 className="font-Gabarito">Add task</h2>
                  </div>
                </Menu>
              </CSSTransition>
        </div>
        <div className="items-center flex">
          {
            isLoggedIn_ ? (
              <div>
                <div onClick={() => setVisibleMenus({ ...visibleMenus, profile: !visibleMenus.profile})} className="cursor-pointer pl-8">
                  <Avatar
                    size={45}
                    name={user?.name}
                    variant="beam"
                    colors={["#1E6B72", "#1f696d", "#20676A", "#1E6A6F", "#6d1f23"]}
                  />
                </div>
                <CSSTransition
                  in={visibleMenus.profile}
                  timeout={300}
                  classNames="fade"
                  unmountOnExit
                >
                  <Menu
                    visible={visibleMenus.profile}
                    backgroundColor={"white"}
                    color={"black"}
                  >
                    <div
                      onClick={() => navigate("/dashboard")}
                      className="hover:opacity-80 cursor-pointer mb-1 mt-1 p-1 pr-4 pl-4">
                      <h2 className="font-Gabarito">Dashboard</h2>
                    </div>

                    <div onClick={() => {
                      eraseCookie("jwt-token")
                      navigate(0)
                    }} className="hover:opacity-80 cursor-pointer items-center flex mb-1 mt-1 p-1 pr-4 pl-4">
                      <div className="pr-2"><BiLogOut /></div>
                      <h2 className="font-Gabarito">Log out</h2>
                    </div>
                  </Menu>
                </CSSTransition>
              </div>
            ) : (
              <div onClick={() => navigate("/login")} className="pl-4">
                <FiLogIn size={34} />
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Header