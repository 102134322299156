import { useEffect, useState } from "react"
import { BASE_URL, getCookie, isLoggedIn, setCookie } from "../../utils"
import axios from "axios"
import { TbArrowBigLeftFilled, TbArrowBigRightFilled } from 'react-icons/tb'
import Task from "../../components/task"
import Header from "../../components/header"
import Loading from "../../components/loading"
import User from "../../components/user"
import Title from "../../components/title"
import { useNavigate, useParams } from "react-router-dom"
import WhoUser from "../../components/who_user"
import { TaskType } from "../home"
import { BsFillCloudSnowFill } from "react-icons/bs"
import { BiCloudDrizzle } from "react-icons/bi"

export interface UserType {
  created_on: string,
  completed_on: string,
  bio: string
  email: string,
  name: string,
  quantity: number,
  user_id: string
  social: Social[]
}

export type Social = {
  name: "telegram" | "youtube" | "tiktok" | "instagram" | "twitter" | "facebook" | "snapchat",
  url: string
}


const WhoPeople = () => {

  const [people, setPeople] = useState<UserType[][]>([[]])
  const [task, setTask] = useState<TaskType>()
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const taskId = useParams().taskId
  const navigate = useNavigate()

  const getTask = async () => {
    setIsLoading(true)
    const guest = !(await isLoggedIn());
    await axios.get(BASE_URL + "/users/task/" + taskId)
      .then(res => {
        setPeople(res.data.param.users)
        setTask(res.data.param.task)
      })
      .catch(err => navigate("/"))
    setIsLoading(false)
  }

  useEffect(() => {
    getTask()
  }, [])

  return (
    <div className="mt-36 h-screen w-screen justify-around flex">
      <div>
        <Title subtitle={"<h1>These are all the people who completed <br> the task: '" + task?.name + "'</h1>"} />
        <div className="justify-around none-flex">
          <div className="rounded-md shadow-md gap-3 bg-[white] p-2 flex">
            <button style={{ opacity: page == 0 ? "60%" : "100%" }} disabled={page == 0} onClick={() => setPage(page - 1)}>
              <TbArrowBigLeftFilled color="#1f696d" size={34} />
            </button>
            <button style={{ opacity: page == people.length - 1 ? "60%" : "100%" }} disabled={page == people.length - 1} onClick={() => setPage(page + 1)}>
              <TbArrowBigRightFilled color="#1f696d" size={34} />
            </button>
          </div>
        </div>
        <div style={{ minHeight: 240, maxHeight: 640 }} className="items-center overflow-y-scroll mt-4 justify-around flex">
          {
            isLoading ? (
              <Loading />
            ) : (
              people.length == 0 ? (
                <div>
                  <div className="items-center flex">
                    <div className="pr-4">
                      <BiCloudDrizzle color="#1f696d" size={38} />
                    </div>
                    <h1 className="text-xl font-Gabarito">Nobody performed this task yet <br /> Someone needs to get started...</h1>
                  </div>
                </div>
              ) : (
                <>
                  <div className="block-none">
                    <button style={{ opacity: page == 0 ? "60%" : "100%" }} disabled={page == 0} onClick={() => setPage(page - 1)}>
                      <TbArrowBigLeftFilled color="#1f696d" size={34} />
                    </button>
                  </div>
                  <div>
                    {
                      people[page].map((user: UserType) => (
                        <WhoUser
                          name={user.name}
                          quantity={user.quantity}
                          bio={user.bio}
                          completed_on={user.completed_on}
                          social={user.social}
                        />
                      ))
                    }
                  </div>
                  <div className="block-none">
                    <button style={{ opacity: page == people.length - 1 ? "60%" : "100%" }} disabled={page == people.length - 1} onClick={() => setPage(page + 1)}>
                      <TbArrowBigRightFilled color="#1f696d" size={34} />
                    </button>
                  </div>
                </>
              )
            )
          }
        </div>
      </div>
    </div>
  )

}

export default WhoPeople