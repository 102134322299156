import { useEffect, useState } from "react"
import { BASE_URL, getCookie, isLoggedIn, setCookie } from "../../utils"
import axios from "axios"
import Task from "../../components/task"
import Header from "../../components/header"
import Loading from "../../components/loading"
import User from "../../components/user"
import Title from "../../components/title"
import { useNavigate } from "react-router-dom"
import { RotateLoader } from "react-spinners"


const Signin = () => {

  const [userSchema, setUserSchema] = useState({
    email: '',
    password: ''
  })

  const [error, setError] = useState<null | string>(null)

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const createTask = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    await axios.post(BASE_URL + "/users/signin", userSchema)
      .then(res => {
        setCookie('jwt-token', res.data.param.token, 14)
        navigate("/")
        navigate(0)
      })
      .catch(err => setError(err.response.data.error.message))
    setIsLoading(false)
  }


  const handleUserSchema = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSchema: any = { ...userSchema }
    newSchema[e.target.name] = e.target.value
    setUserSchema(newSchema)
  }


  return (
    <div className="mt-36 h-screen w-screen justify-around flex">
      <div>
        <h1 className="font-semibold text-2xl font-Gabarito">LOGIN</h1>
        <h2 className="text-[gray] text-lg font-Gabarito">Don't have an account? <br /> <span onClick={() => navigate("/signup")} className="decoration-[#1f696d] underline">Create one</span> in 2 minutes</h2>
        <div className="mt-4">
          <form onSubmit={(e) => createTask(e)} action="">
            <div className="pl-0 p-2">
              <h2 className="pb-1 text-lg font-Gabarito">E-mail</h2>
              <input
                autoFocus
                placeholder="example@gmail.com"
                className="pl-4 rounded-md border-2 border-[] p-3"
                onChange={(e) => handleUserSchema(e)}
                type="text"
                name="email"
                value={userSchema.email}
              />
            </div>
            <div className="pl-0 p-2">
              <h2 className="pb-1 text-lg font-Gabarito">Password</h2>
              <input
                autoFocus
                placeholder="Your secret password..."
                className="pl-4 rounded-md border-2 border-[] p-3"
                onChange={(e) => handleUserSchema(e)}
                type="password"
                name="password"
                value={userSchema.password}
              />
            </div>
            {
              error ? (
                <div className="pt-2">
                  <h1 className="text-[red] font-semibold text-md font-Gabarito">{error}</h1>
                </div>
              ) : null
            }
            {
              isLoading ? (
                <div className="pl-0 p-4">
                  <button className="h-14 rounded-md font-Gabarito w-32 text-[white] bg-[#1f696d] p-2">
                    <RotateLoader size={4} color="white" loading={true} />
                  </button>
                </div>
              ) : (
                userSchema.email !== "" && userSchema.password !== "" ? (
                  <div className="pl-0 p-4">
                    <button type="submit" className="h-14 rounded-md font-Gabarito w-32 text-[white] bg-[#1f696d] p-2">LOGIN</button>
                  </div>
                ) : (
                  <div className="pl-0 p-4">
                    <button disabled className="h-14 rounded-md bg-opacity-60 font-Gabarito w-32 text-[white] bg-[#1f696d] p-2">LOGIN</button>
                  </div>
                )
              )
            }
          </form>
        </div>
      </div>
    </div>
  )

}

export default Signin