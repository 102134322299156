import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Header from './components/header';
import People from './pages/people';
import Create from './pages/create';
import Signin from './pages/signin';
import WhoPeople from './pages/who_people';
import Signup from './pages/signup';
import Dashboard from './pages/dashboard';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<><Header page='home'/><Home/></>} />
        <Route path="/dashboard" element={<><Header page={null}/><Dashboard/></>} />
        <Route path="/login" element={<><Signin/></>} />
        <Route path="/signup" element={<><Signup/></>} />
        <Route path="/people" element={<><Header page='people'/><People/></>} />
        <Route path="/people/task/:taskId" element={<><Header page='people'/><WhoPeople/></>} />
        <Route path="/create" element={<><Header page='create'/><Create/></>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
